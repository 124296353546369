import React, {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
   Accordion, Modal, Badge, Dropdown, Fade} from 'react-bootstrap';
import '../../assets/scss/style.scss';
import Aux from "../../hoc/_Aux";
import Fullscreen from "react-full-screen";
import { toast } from 'react-toastify';
import Colors from '../../utils/styles/Colors'
import {Pdpa} from '../../utils/Data'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import api from '../../utils/Api'
import DatePicker from "react-datepicker";
import DatepickerInput from '../../components/DatepickerInput'
import moment from 'moment'

export const banks = [
    {
        label: 'ธนาคารกสิกรไทย',
        value: 'kbank',
        color: '#138f2d',
        circularIcon: true,
    },
    {
        label: 'ธนาคารกรุงเทพ',
        value: 'bbl',
        color: '#1e4598',
        circularIcon: false,
    },
    {
        label: 'ธนาคารไทยพาณิชย์',
        value: 'scb',
        color: '#4e2e7f',
        circularIcon: false,
    },
    {
        label: 'ธนาคารกรุงศรีอยุธยา',
        value: 'bay',
        color: '#fec43b',
        circularIcon: true,
    },
    {
        label: 'ธนาคารกรุงไทย',
        value: 'ktb',
        color: '#1ba5e1',
        circularIcon: false,
    },
    {
        label: 'ธนาคารทหารไทย',
        value: 'tmb',
        color: '#1279be',
        circularIcon: false,
    },
    {
        label: 'ธนาคารซีไอเอ็มบี',
        value: 'cimb',
        color: '#7e2f36',
        circularIcon: false,
    },
    {
        label: 'ธนาคารยูโอบี',
        value: 'uob',
        color: '#0b3979',
        circularIcon: false,
    },
    {
        label: 'ธนาคารแลนด์ แอนด์ เฮาส์',
        value: 'lhb',
        color: '#6d6e71',
        circularIcon: false,
    },
    {
        label: 'ธนาคารสแตนดาร์ดฯ',
        value: 'sc',
        color: '#0f6ea1',
        circularIcon: false,
    },
    {
        label: 'ธนาคารธนชาติ',
        value: 'tbank',
        color: '#fc4f1f',
        circularIcon: false,
    },
    {
        label: 'ธนาคารออมสิน',
        value: 'gsb',
        color: '#eb198d',
        circularIcon: true,
    },
    {
        label: 'ธนาคารเกียรตินาคิน',
        value: 'kk',
        color: '#199cc5',
        circularIcon: true,
    },
    {
        label: 'ธนาคารทิสโก้',
        value: 'tisco',
        color: '#12549f',
        circularIcon: false,
    },
];

const textFilter = (item, inputValue)=>{
    if (inputValue) {
        const regexp = new RegExp(inputValue, 'i')
        return item.label.match(regexp)
    }

    return true
}

function Avatar({profileImage}) {
  const [image, setImage] =  useState(profileImage?URL.createObjectURL(profileImage):null)

  React.useEffect(()=>{
    if (profileImage)
      setImage(URL.createObjectURL(profileImage))
  }, [profileImage])

  return (
    <>
    {image?<div style={{ width: '100%', height: 0, paddingTop: '100%',position: 'relative'}}>
        <div style={{
          width: '100%', height: '100%', position: 'absolute', top: 0,
          background:'url('+(image)+') no-repeat center center / cover',
        }}/>
        <div className="p-2 text-center" style={{position: 'absolute', left: -3, bottom: -3, right: -3, background: 'rgba(255,255,255,0.65)'}}>
          <span className="text-center">{'คลิกที่นี่เพื่อเลือกรูป'}</span>
        </div>
      </div>:(
      <div className="row-center" style={{ width: '100%', height: 0, paddingTop: '100%',position: 'relative', backgroundColor: Colors.gray100, borderRadius: 8}}>

        <div style={{
          width: '100%', height: '100%', position: 'absolute', top: 0,
        }} className="row-center">
       <i style={{fontSize: '2rem', color: Colors.gray400}} className="fa fa-user"></i>
       </div>
       <div className="p-2 text-center" style={{position: 'absolute', left: -3, bottom: -3, right: -3, background: 'rgba(255,255,255,0.65)'}}>
         <span className="text-center" >{'คลิกที่นี่เพื่อเลือกรูป'}</span>
       </div>
       </div>
    )}
    </>
  )
}


const optionValue = (_id, opts) => {
  if (!_id) {
    return null
  }

  return _.find(opts, ['value', _id])
}

export default function BusinessInfo({onSubmit, shopId, onBack}) {
  const [profileImage, setProfileImage] = useState(null)
  const [pdpa, setPdpa] = useState(false)
  const [consent, setConsent] = useState(false)

  const [form, setForm] = useState({
    work_start_date: new Date,
    employee_start_date: new Date,
    date_of_birth: new Date(moment().subtract(15, 'years'))
  })

  const {work_start_date, employee_start_date, date_of_birth} = form

  const fetchRoles = (inputValue, callback) => {

      api.request('shop-role', (users, s)=>{
          if (!s) return
          const opt = users.filter((item, index)=>index>0).map((user)=>{
              return {
                  label: user.name,
                  value: user._id,
              }
          })



          callback(opt.filter((item)=>textFilter(item, inputValue)))
      }, {shop_id: shopId})
  };

  const fetchOfficers = (inputValue, callback) => {
    api.request('shop-officer', (users, s)=>{
        if (!s) return
        const opt = users.map((user)=>{
            return {
                label: user.name,
                value: user._id,
            }
        })

        callback(opt.filter((item)=>textFilter(item, inputValue)))
    }, {shop_id: shopId})
  };

  const fetchGroups = (inputValue, callback) => {
    api.request('shop-officer-group', (users, s)=>{
        if (!s) return
        const opt = users.map((user)=>{
            return {
                label: user.name,
                value: user._id,
            }
        })

        callback(opt.filter((item)=>textFilter(item, inputValue)))
    }, {shop_id: shopId})
  };

  return (
    <Aux>
        <div className="full-screen bg-white">
          <Container fluid>
            <Row>
              <Col className="column-center py-4" xs={12}>
                <h3>กรอกข้อมูลพนักงาน</h3>
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={4} className="mb-3 d-flex justify-content-center mt-5">
                <div style={{width: 250}}>
                  <div className="custom-file-input-container" style={{ borderRadius: 8, border: '3px solid '+Colors.primaryDark}}>
                    <Avatar profileImage={profileImage} />
                    <input type="file" name="profile_image" onChange={(e)=> {
                      setProfileImage(e.target.files[0])
                    }} />
                  </div>
                </div>
              </Col>
              <Col sm={12} lg={4} className="mb-3">
                  <div className="d-flex flex-column h-100">
                    <Row style={{width: 500, maxWidth: '100%'}}>
                      <Col xs={12}c className="mt-3">
                        <div>
                          <label>ชื่อ-นามสกุล(ภาษาไทย):<span className="text-danger">*</span></label>
                        </div>
                        <div>
                          <Form.Control

                            onChange={(e)=>setForm({...form,
                                name: e.target.value
                            })}
                          />
                        </div>
                      </Col>
                      <Col xs={12}c className="mt-3">
                        <div>
                          <label>ชื่อ-นามสกุล(ภาษาอังกฤษ):<span className="text-danger">*</span></label>
                        </div>
                        <div>
                          <Form.Control

                            onChange={(e)=>setForm({...form,
                                name_en: e.target.value
                            })}
                          />
                        </div>
                      </Col>
                      <Col xs={12}c className="mt-3">
                        <div>
                          <label>ชื่อเล่น:<span className="text-danger">*</span></label>
                        </div>
                        <div>
                          <Form.Control
                            onChange={(e)=>setForm({...form,
                                nickname: e.target.value
                            })}
                          />
                        </div>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <div>
                          <label>เพศ:</label>
                        </div>
                        <Form.Group className={"row-vcenter mb-0 secondary-radio"}>
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label={'ชาย'}
                                name={'gender'}
                                value={'m'}
                                id={'gender'}
                                className="mr-3"
                                onChange={(e)=>setForm({...form,
                                    gender: 'm'
                                })}
                            />
                            <Form.Check
                                inline
                                custom
                                type="radio"
                                label={'หญิง'}
                                name={'gender'}
                                value={'f'}
                                id={'gender'}
                                className="mr-3"
                                onChange={(e)=>setForm({...form,
                                    gender: 'f'
                                })}
                            />
                        </Form.Group>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <div>
                          <label>เลขบัตรประชาชน:</label>
                        </div>
                        <div>
                          <Form.Control
                            placeholder=""
                            maxLength={13}
                            onKeyDown={ (e) => {
                              if (!(/^[0-9]$/i.test(e.key) || e.metaKey || e.which <= 0 || e.which == 8 )) {
                                e.preventDefault()
                              }
                             } }
                            onChange={(e)=>setForm({...form,
                                id_card: e.target.value
                            })}
                          />
                        </div>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <div>
                          <label>โทรศัพท์:<span className="text-danger">*</span></label>
                        </div>
                        <div>
                          <Form.Control
                            placeholder=""

                            onKeyDown={ (e) => {

                              if (!(/^[0-9]$/i.test(e.key) || e.metaKey || e.which <= 0 || e.which == 8 )) {
                                e.preventDefault()
                              }

                              if (e.target.value.length >= 10 && !(e.metaKey || e.which <= 0 || e.which == 8)) {
                                e.preventDefault()
                              }
                             } }


                            onChange={(e)=>setForm({...form,
                                mobile: e.target.value.replace(/[^0-9]/g, '').substr(0,10)
                            })}
                            value={form.mobile}
                          />
                        </div>
                      </Col>
                      <Col xs={12} className="mt-3">
                        <div>
                          <label>วันเกิด:</label>
                        </div>
                        <div className="w-100">
                            <DatePicker customInput={<DatepickerInput />}
                                selected={date_of_birth}
                                onChange={(date_of_birth) => setForm({...form, date_of_birth})}
                                className="form-control"
                                dateFormat="dd MMMM yyyy"
                                timeCaption="time"
                                showMonthDropdown
                                showYearDropdown
                                maxDate={new Date(moment().subtract(15, 'years'))}
                                yearDropdownItemNumber={8}
                            />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={12} lg={4} className="mb-3">
                    <div className="d-flex flex-column h-100">
                      <Row style={{width: 500, maxWidth: '100%'}}>
                        {/*<Col xs={12} className="mt-3">
                          <div>
                            <label>รหัสพนักงาน:<span className="text-danger">*</span></label>
                          </div>
                          <div>
                            <Form.Control
                              placeholder=""
                              onChange={(e)=>setForm({...form,
                                  employee_code: e.target.value
                              })}
                            />
                          </div>
                        </Col>*/}
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>ตำแหน่ง:<span className="text-danger">*</span></label>
                          </div>
                          <div>
                              <AsyncSelect
                                loadOptions={fetchRoles}
                                defaultOptions
                                onChange={({value})=>setForm({...form, role_id: value})}
                                placeholder="เลือกตำแหน่งงาน"
                              />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>เลือกกลุ่ม:<span className="text-danger">*</span></label>
                          </div>
                          <div>
                              <AsyncSelect
                                loadOptions={fetchGroups}
                                defaultOptions
                                onChange={({value})=>setForm({...form, group_id: value})}
                                placeholder="เลือกกลุ่ม"
                              />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>วันที่เริ่มงานวันแรก:<span className="text-danger">*</span></label>
                          </div>
                          <div className="w-100">
                              <DatePicker customInput={<DatepickerInput />}
                                  selected={work_start_date}
                                  onChange={(work_start_date) => setForm({...form, work_start_date})}
                                  className="form-control"
                                  dateFormat="dd MMMM yyyy"
                                  timeCaption="time"
                                  showMonthDropdown
                                  showYearDropdown
                                  yearDropdownItemNumber={5}
                              />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>วันที่เป็นพนักงานประจำวันแรก:<span className="text-danger">*</span></label>
                          </div>
                          <div className="w-100">
                              <DatePicker customInput={<DatepickerInput />}
                                  selected={employee_start_date}
                                  onChange={(employee_start_date) => setForm({...form, employee_start_date})}
                                  className="form-control"
                                  dateFormat="dd MMMM yyyy"
                                  timeCaption="time"
                                  showMonthDropdown
                                  showYearDropdown
                                  yearDropdownItemNumber={5}
                              />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>ผู้อนุมัติคำร้อง:</label>
                          </div>
                          <div>
                              <AsyncSelect
                                loadOptions={fetchOfficers}
                                defaultOptions
                                onChange={({value})=>setForm({...form, approver_id: value})}
                                placeholder="เลือกผู้อนุมัติ"
                                menuPlacement="auto"
                              />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>ธนาคาร:</label>
                          </div>
                          <div>
                            <Select
                                options={banks}
                                onChange={(option)=>{
                                    setForm({...form, bank: option.value})
                                }}
                                menuPlacement="auto"
                                placeholder="เลือกธนาคาร"
                                value={optionValue(form.bank, banks)}
                            />
                          </div>
                        </Col>
                        <Col xs={12} className="mt-3">
                          <div>
                            <label>เลขบัญชี:</label>
                          </div>
                          <div>
                            <Form.Control
                              placeholder=""
                              onChange={(e)=>setForm({...form,
                                  account_no: e.target.value
                              })}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                <Col xs={12} className="d-flex justify-content-end mr-3 mt-3 mb-3">
                  <Button onClick={()=>onBack()} className="mr-3" size="md" variant="dark">Back</Button>
                  <Button onClick={()=>{
                    if(!form.name){
                      toast.warn('กรุณากรอก ชื่อ-นามสกุล(ภาษาไทย)')
                      return
                    }
                    if(!form.name_en){
                      toast.warn('กรุณากรอก ชื่อ-นามสกุล(ภาษาอังกฤษ)')
                      return
                    }
                    if(!form.nickname){
                      toast.warn('กรุณากรอกชื่อเล่น')
                      return
                    }
                    if(!form.mobile){
                      toast.warn('กรุณากรอกเบอร์โทรศัพท์')
                      return
                    }
                    if(!form.role_id){
                      toast.warn('กรุณากรอกตำแหน่ง')
                      return
                    }
                    if(!form.group_id){
                      toast.warn('กรุณากรอกเลือกกลุ่ม')
                      return
                    }

                    setConsent(true)
                  }} size="md" variant="secondary">Confirm</Button>
                </Col>
            </Row>
          </Container>
        </div>
        <Modal scrollable centered show={consent} onHide={()=>{
          setConsent(false)}}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h4>ข้อตกลงและการยินยอมในการบันทึกข้อมูล</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Pdpa/>
            <Form.Group>
              <Form.Check
                inline
                custom
                value={pdpa}
                label={''}
                type="checkbox"
                //feedback={this.state.supportedRadio ? false : "More example invalid feedback text."}
                onChange={(v) => {
                  setPdpa(!pdpa)
                }}
              />
              <Form.Label>
                ข้าพเจ้าได้อ่าน และเข้าใจรายละเอียดการขอความยินยอมข้างต้น
              </Form.Label>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>{
              setConsent(false)}}>Close</Button>
            <Button disabled={!pdpa} onClick={()=>{
              onSubmit({...form, work_start_date: moment(work_start_date).toISOString(),
              employee_start_date: moment(employee_start_date).toISOString(),
              date_of_birth: moment(date_of_birth).toISOString()}, profileImage)
            }} size="md" variant="secondary">Submit</Button>
          </Modal.Footer>
        </Modal>
    </Aux>
  )
}
