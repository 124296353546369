import React, {useState, useEffect, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import {Row, Col, Container, Card, Table, Button, Form, InputGroup, FormControl,
   Accordion, Modal, Badge, Dropdown, Fade} from 'react-bootstrap';
import './../assets/scss/style.scss';
import Aux from "../hoc/_Aux";
import logoDark from '../assets/images/logo-dark.png';
import Fullscreen from "react-full-screen";
import Info from './officerRegister/Info'
import api from '../utils/Api'
import { ToastContainer, toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {FullscreenLoading} from '../components/Modals'

const MySwal = withReactContent(Swal);

export default function OfficerRegister({location, history}) {
    const [showBusinessInfo, setShowBusinessInfo] = useState(false)
    const queryParams = Object.fromEntries(new URLSearchParams(location.search)) || {}
    const {id, secret} = queryParams
    const [signingUp, setSigningUp] = useState(false)
    const [shopId, setShopId] = useState()
    const [registering, setRegistering] = useState(false)


    const [form, setForm] = useState({})

    useEffect(()=>{
        api.request(`invitation/${id}`, (r, s)=>{
          if (!s) {
            MySwal.fire({
              title: 'ไม่สามารถสมัครได้', type: 'error',
              text: r.response.data.message || 'กรุณาคลิกลิงค์ที่ได้จากอีเมล',
              confirmButtonText:'ไปที่ Website HubSwitch'
            }).then(()=>window.location = window.location.hostname.match(/admin\-dev/g)||window.location.hostname.match(/localhost/g)?'https://dev.hubswitch.com':'https://hubswitch.com');
          } else {
            setShopId(r.shop_id)
          }
        }, {secret}, 'GET')
    }, [])


    return(
      <Aux>
          <div className="full-screen row-vcenter bg-white register">
              <Container>
                <Row>
                  <Col sm={{span: 12, offset: 0}} md={{span: 6, offset: 3}}>
                    <Row>
                      <Col xs={12} className="mb-5">
                        <h2 className="text-primary text-center">Create Account</h2>
                      </Col>
                      <Col xs={12} md={6}>
                        <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-user"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              placeholder="Name"
                              onChange={(e)=>setForm({...form,
                                firstname: e.target.value
                              })}
                            />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-user"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              placeholder="Lastname"
                              onChange={(e)=>setForm({...form,
                                lastname: e.target.value
                              })}
                            />
                        </InputGroup>
                      </Col>
                      <Col xs={12}>
                        <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-envelope"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              placeholder="Email"
                              onChange={(e)=>setForm({...form,
                                email: e.target.value
                              })}
                            />
                        </InputGroup>
                      </Col>
                      <Col xs={12}>
                        <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-lock"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              onChange={(e)=>setForm({...form,
                                password: e.target.value
                              })}
                            />
                        </InputGroup>
                      </Col>
                      <Col xs={12}>
                        <InputGroup className="mb-4">
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <i className="fa fa-lock"></i>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              onChange={(e)=>setForm({...form,
                                confirm_password: e.target.value
                              })}
                            />
                        </InputGroup>
                      </Col>
                      <Col xs={12} className="row-center mt-3">
                        <Button onClick={()=>{
                          if (form.confirm_password != form.password) {
                            toast.warn('รหัสผ่านไม่ตรงกัน')
                            return
                          }

                          if (!form.password) {
                            toast.warn('กรุณากรอกรหัสผ่าน')
                            return
                          }

                          if (form.password.length < 6) {
                            toast.warn('กรุณากรอกรหัสผ่าน 6 ตัวอักษรขึ้นไป')
                            return
                          }

                          if (!form.firstname) {
                            toast.warn('กรุณากรอกชื่อ')
                            return
                          }

                          if (!form.email) {
                            toast.warn('กรุณากรอกอีเมล')
                            return
                          }

                          api.request(`invitation/${id}`, ({registered, shop}, s)=>{
                            if (!s) {
                              return
                            }
                            if (registered) {
                              MySwal.fire({
                                title: 'สมัครสมาชิกสำเร็จ', type: 'success',
                                text: 'คุณสมัครเป็นสมาชิกของร้าน ' + shop.name + ' สำเร็จแล้ว !',
                                confirmButtonText:'ไปที่หน้า Login'
                              }).then(()=> history.replace('/login'))

                              return
                            }

                            setShowBusinessInfo(true)
                          }, {secret, email: form.email}, 'GET', {showError: true})


                        }} size="md" variant="secondary">Next</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              {shopId &&
              <Modal size="lg" className="full-screen" onHide={()=>setShowBusinessInfo(false)}
               centered show={showBusinessInfo}>
                <Info shopId={shopId} onBack={()=>setShowBusinessInfo(false)} onSubmit={(info, profileImage)=>{
                  setRegistering(true)
                  api.request('officer/register', (r, s)=>{
                    setRegistering(false)
                    if (s) {
                      MySwal.fire({
                        title: 'สมัครสมาชิกสำเร็จ', type: 'success',
                        text: 'ท่านสามารถเข้าสู่ระบบได้แล้ว',
                        confirmButtonText:'ไปที่หน้า Login'
                      }).then(()=> history.replace('/login'))
                    }
                  }, api.form({...form, name: form.firstname+' '+form.lastname, ...info, shop_id: shopId}, profileImage?{profile_image: profileImage}:{}), 'POST')
                }} />
              </Modal>
              }
          </div>
          <div style={{position: 'absolute', top: 40, left: 40}}>
            <h4 className="text-muted cursor-pointer">HubSwitch</h4>
          </div>
          <FullscreenLoading show={registering}  />
          <ToastContainer autoClose={3000} />
      </Aux>
    );
}
